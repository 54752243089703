<template>
  <ion-page>
    <HeaderPage :title="post.title" v-if="post" />
    <ion-content :fullscreen="true" v-if="post">
      <div class="aspect-ratio-box" v-if="post.image">
        <img :src="post.image" :alt="post.title" />
      </div>
      <div class="container">
        <div class="date text-sm">{{ $filters.dateFormat(post.created_at, 'full') }}</div>
        <div class="title">{{ post.title }}</div>
        <markdown :source="post.content"></markdown>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage} from '@ionic/vue'
import HeaderPage from '@/components/headerPage'
import Markdown from 'vue3-markdown-it'

export default {
  components: {
    Markdown,
    HeaderPage,
    IonContent,
    IonPage
  },
  created(){
    this.loadPost()
  },
  data () {
    return {
      post: null
    }
  },
  watch: {
    '$route': 'loadPost'
  },
  methods: {
    async loadPost() {
      let slug = this.$route.params.slug
      if(slug == undefined)
        return;

      let res = await this.$api.get(`/api/post/${slug}`)
      this.post = res.data
      document.title = 'doo.id - ' + this.post.title
    }
  }
}
</script>

<style scoped>
.title {
  font-weight: bold;
}
</style>
